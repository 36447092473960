//
// quilljs-editor.scss
//
 
.ql-container {
    font-family: $font-family-base;
}

.ql-bubble {
    border: $input-border-width solid $input-border-color;
    border-radius: $input-border-radius;
    box-shadow: $box-shadow-sm;
}

.ql-toolbar {
    font-family: $font-family-base !important;
    box-shadow: $box-shadow-sm;

    span {
        outline: none !important;
    }
}